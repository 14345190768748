export const displaySalaryAmount = (number, period) => {
  const num = typeof number === 'string' ? parseFloat(number) : number;

  if (period === 'hour') {
    return num.toString();
  }

  if (num >= 1000000) {
    return `${Math.round(num / 100000) / 10}M`;
  }
  if (num >= 1000) {
    return `${Math.round(num / 100) / 10}k`;
  }

  return Math.round(num).toString();
};

export const formatCents = (cents) => {
  const dollars = cents / 100;
  if (dollars < 1000) {
    return cents % 100 === 0 ? dollars.toString() : dollars.toFixed(2);
  }
  return `${(dollars / 1000).toString()}k`;
};

export const getCompensationConvertedLabel = ({
  convertedAmountMinCents,
  convertedAmountMaxCents,
  convertedCurrency,
}) => ` (${convertedCurrency} ${formatCents(convertedAmountMinCents)}-${formatCents(convertedAmountMaxCents)})`;

export const displayCompensationPeriodLabel = (compensation = {}) => {
  const {
    amountMinCents,
    amountMaxCents,
    currency,
    period,
    offersEquity,
    convertedAmountMinCents,
    convertedAmountMaxCents,
    convertedCurrency,
  } = compensation;
  const converted =
    convertedAmountMinCents && convertedAmountMaxCents && currency !== convertedCurrency
      ? getCompensationConvertedLabel({ convertedAmountMinCents, convertedAmountMaxCents, convertedCurrency, period })
      : '';

  return `${currency} ${formatCents(amountMinCents)}-${formatCents(
    amountMaxCents,
  )} / ${period.toLowerCase()}${converted}${offersEquity ? ' + Equity' : ''}`;
};
