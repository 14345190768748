import { calcAPCA } from 'apca-w3';
import { siteTheme } from '../theme';

const cache = {};
export const getAPCAColor = (hasAdvancedDesign, customColors) => {
  const { colors } = customColors && hasAdvancedDesign ? { colors: customColors } : siteTheme;

  const defaultColors = {
    accent: colors.customAccent,
    primary: colors.customPrimary,
  };
  if (!hasAdvancedDesign) {
    return defaultColors;
  }

  const key = `${colors.customAccent}-${colors.customPrimary}`;
  const isAPCAStandard = key in cache ? cache[key] : Math.abs(calcAPCA(colors.customAccent, colors.customPrimary));
  const accent = isAPCAStandard >= 60 ? colors.customAccent : '#ffffff';
  const primary = isAPCAStandard >= 60 ? colors.customPrimary : 'neutral.700';
  cache[key] = isAPCAStandard;

  return {
    accent,
    primary,
  };
};
